.mapWrapper {
  height: 60vh;
  width: 100vw;
}

.locationListContainer {
  height: 60vh;
  width: 30vw;
  overflow: auto;
  margin: 5px;
}

.locationListContainer::-webkit-scrollbar {
  width: 5px;
}
.locationListContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.locationListContainer::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.locationListItem {
  margin: 10px;
  padding: 5px;
}
.locationListItem:hover {
  cursor: pointer;
  background-color: #ddd;
}
.kioskMatches {
  color: #fafafa;
  background-color: #00a652;
  text-align: center;
  height: 20px;
  width: 25px;
  padding-bottom: 5px;
  border-radius: 12px;
}

.mapMarker {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #a63424;
  border-radius: 8px;
  width: 7px;
  height: 7px;
  border: 2px solid #a63424;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
}
.mapMarker:hover {
  cursor: pointer;
}
.closeButton {
  margin-top: -5px;
}
.closeButton:hover {
  cursor: pointer;
}
.windowContent {
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.markerInfoWindow {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #fff;
  border-radius: 8px;
  width: 200px;
  height: auto;
  padding: 10px;
  z-index: 9900;
  box-shadow: 2px 3px rgb(0 0 0 / 0.4);
}

.infoName {
  font-weight: bold;
}
.infoAddress {
}
@media screen and (min-width: 300px) and (max-width: 768px) {
  .kioskLocationsWrapper {
    margin-top: -38px;
  }
  .kioskLocationsWrapper > h1 {
    font-size: 1rem;
  }
  .kioskLocationsWrapper > h4 {
    font-size: 0.8rem;
    margin: 0px;
  }
  .locationWrapper {
    flex-direction: column;
    flex-direction: column-reverse;
  }
  .mapWrapper {
    height: 45vh;
    width: 96vw;
  }
  .locationListContainer {
    height: 60vh;
    width: 96vw;
    overflow: auto;
    margin: 0.5vh 0.2vw;
  }
  .locationListItem {
    margin: 5px 1px;
    padding: 5px;
  }
  .locationListContainer::-webkit-scrollbar {
    width: 2px;
    border-radius: 6px;
  }
}

@media screen and (min-width: 768px) {
}
