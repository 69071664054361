.fp-footer-links{
    height: 80px;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #e8e6e6;
}

.fp-form-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fp-form-button-row{
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}
.rule-wrapper{
    min-height: 25px;
    display: flex;
    gap: 5px;
}
.cp-form-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    margin-top: 20px;
}
.li-form-wrapper {
    display: flex;
    gap:20px;
}
.li-form-section{
    width: 400px;
}

.primary-info-card-list {
    margin-left: -20px;
    margin-top: 0px;
}

@media  (min-width: 320px) and (max-width: 768px) {
.li-form-wrapper {
    flex-flow: column wrap;
}
.li-form-section{
    width: 100%;
}
}