/* /**
/ GLOBAL DIALOG STYLES
**/
.globalDialogTitle {
  color: #ffffff !important;
  background-color: #3c7da4 !important; /* blue */
  text-transform: uppercase;
}

.globalDialog {
  position: absolute;
  display: flex;
  z-index: 9999;
  background-color: #444444aa;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.globalDialogContentUL {
  margin-block-end: 0px;
  margin-block-start: 0px;
  padding-left: 20px;
}
.globalDialogInstruction {
  padding: 15px 15px 15px 15px;
  background-color: #fef7de !important; /* yellow/tan */
  color: #7e735a !important;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 25px;
  margin-bottom: 15px;
  height: 10px;
  overflow: hidden !important;
  /* text-align: left */
}
.dialogActions {
  justify-content: center;
  min-width: 260px;
  /* margin: 0px 15px 15px 0px; */
}

.globalAlertLinks {
  border: none;
}
