html {
  min-height: -webkit-fill-available;
}
body {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
  color: #646e70;
  min-height: 100vh;
  min-height: -webkit-fill-available;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
 position:relative;
  top:100px;
  max-width: 800px;
  padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}