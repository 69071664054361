.ot-btn-anchor {
  text-decoration: underline;
  color: #00a652;
}
.ot-exercise-button-light {
  background: #eee;
  border: 1px solid #aaa;
}

.ot-exercise-button-light {
  margin-left: 44px;
}

.ot-exercise-button {
  margin-top: 10px;
  padding: 6px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.ot-exercise-button img {
  margin-right: 0px;
  vertical-align: middle;
}

.ot-exercise-button .ot-text-container {
  vertical-align: middle;
  display: inline-block;
  margin-top: -4px;
  color: #6cc04a;
  font-size: 13px;
}

.ot-exercise-button .ot-subtext {
  float: right;
  margin-top: 0px;
  font-size: 11px;
}

.ot-exercise-button .ot-powered-by-text {
  font-size: 7px;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  vertical-align: top;
  line-height: 16px;
}

.ot-exercise-button.ot-exercise-button-light .ot-powered-by-text {
  color: #3c474c;
}
