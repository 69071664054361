.blueLink {
  color: #3c7da4;
  font-size: 14px;
  margin-top: 10px;
}
.blueLink:hover {
  cursor: pointer;
  text-decoration: underline;
}
.fullScreenLoader {
  z-index: 900;
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  background-color: #3c7da444;
}
.viewHeaderName {
  background-color: #3c7da4;
  text-transform: uppercase;
  color: #fafafa;
  border-radius: 3px 3px 0px 0px;
  padding: 10px 15px;
}

.formWrapper {
  gap: 5px; /* 20px for desktop view */
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* min-height: 382px; */
  /* background-color: #f5f5f5; */
}

/* .textFieldWarpper {
  height: 80px;
} */
.checkboxForTextField {
  margin-top: -10px;
  margin-bottom: 10px;
}
@media (max-width: 800px) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  .formWrapper {
    flex-direction: column;
  }
}

/* MODAL STYLES */
.modal-header {
  color: #ffffff;
  background-color: #3c7da4 !important; /* blue */
  padding: 5px 15px 60px 15px;
  height: 5px;
}

.modal-header h3 {
  color: #ffffff; /* white */
  padding: 12px;
}

.modal-header h1#modal-title.panel-title {
  font-weight: 500;
  font-size: x-large;
  white-space: nowrap;
}

.modal-footer {
  background: #f2f2f2; /* eggshell white/tan */
  padding: 15px 15px 30px 10px;
  text-align: right;
  background-color: #fff;
}

.addressItem{
  padding-top: 5px;
}

.suggestedAddressItem {
  padding-top: 5px;
}

.addressInstruction {
  padding: 10px 15px;
  background-color: #fef7de !important; /* yellow/tan */
  color: #7e735a !important;
  margin: 15px;
  overflow: hidden !important;
}
.address-suggestion-radio {
  font-weight: bold;
  height: 25px;
}

.address-suggestion-radio input {
  height: 25px;
  width: 25px;
  margin: 8px 10px 0 0;
}

.suggestionsContainer {
  padding: 0px 20px 0px 80px;
}

.addressSuggestions {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 9999;
  background-color: #444444aa;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100vw;
}
.suggestionContentWrapper {
  background-color: #fff;
  max-width: 700px;
}
