/* Small mobile */
@media (min-width: 320px) and (max-width: 768px) {
  .familyBkgWrapper {
    height: 330px;
  }
  
  .familyText {
    top: 40px;
    height: auto;
  }
}

/* Desktop */
@media (min-width: 769px) {
  .appStoreIconWrapper {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .familyBkgWrapper {
    height: 717px;
  }

  .familyText {
    top: 32%;
    margin: 0px auto;
    justify-content: center;
  }
}
