.App {
  display: flex;
  flex-direction: column;
  margin: 100px auto 0px auto;
  max-width: 800px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.networkStatusMessage, .networkRetoredMessage{
  background-color: #e9bc00;
  text-align: center;
  font-weight: bold;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  width: 100vw;
  z-index:9999;
}

.networkRetoredMessage {
  background-color: #00A652;
  color: #fafafa;
  -moz-animation: networkStatusAnimation 1s ease-in 5s forwards;
    /* Firefox */
    -webkit-animation: networkStatusAnimation 1s ease-in 5s forwards;
    /* Safari and Chrome */
    -o-animation: networkStatusAnimation 1s ease-in 5s forwards;
    /* Opera */
    animation: networkStatusAnimation 1s ease-in 5s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes networkStatusAnimation {
  to {
      opacity:0;
      height: 0;
      overflow:hidden;
  }
}
@-webkit-keyframes networkStatusAnimation {
  to {
      opacity:0;
      height:0;
      visibility:hidden;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.formButtonRow {
  width: 100%;
  /* margin: 10px 10px 10px -10px; */
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  gap: 10px;
}

.dashboardButtonRow {
  width: 100%;
  text-align: center;
}

@media  (min-width: 320px) and (max-width: 768px) {
  .formButtonRow {
    width: 100%;
    /* margin: 10px 10px 10px -10px; */
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    gap: 10px;
  }

  .mobileSectionContainer{
    padding-bottom: 60px;
  }

  .azLogo{
    width: 100%;
    height: auto;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Dialog customisations */
.dialogContentWarning{
   padding: 20px;
   background-color: rgb(255, 244, 229);
}
